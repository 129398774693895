.removeSlide_form{
    width: 100%;
    padding: 1rem;
}


.removeSlide_div--button{
    display: flex;
    gap: 1rem;
}

.removeSlide_form--buttonDiv{
    display: flex;
    justify-content: flex-end;
    width: 90%;
}

.removeSlide_form--slideDiv{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 50vh;
    margin-top: 1rem;
}

.removeSlide_button--page{
    width: 5rem;
    background-color: #000000;
    border-radius: 0.6rem;
}