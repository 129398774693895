.budgetList_ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 70%;
}

.budgetList_ul--buttonDiv{
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 0;
}

.budgetList_ul--button{
    width: 4rem;
    height: 2rem;
    border-radius: 10px 10px 10px 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 1.4rem;
    font-size: 1rem;
    background-color: #0066CC;
}