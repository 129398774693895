.contact_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5.5rem;
    width: 100%;
    min-height: 80vh;
}

.contact_section{
    display: flex;
    padding-left: 2rem;
    width: 100%;
}

.contact_main--title{
    color: #1F1F1F;
    font-weight: 900;
    font-size: 6.25rem;
}

.contact_email--div{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 50%;
}

.contact_email--div_title{
    color: #1F1F1F;
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 1rem;
}

.contact_email--input{
    width: 80%;
    border-radius: 0.6rem;
}

.textarea{
    height: 20vh;
}

.contact_email--button_div{
    display: flex;
    justify-content: flex-end;
    width: 80%;
}

.contact_email--button{
    width: 6rem;
    height: 3.2rem;
    background-color: #000000;
    border-radius: 0.6rem;
}

.contact_info--div{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 2rem;
}

.contact_info--title_div{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.contact-info-title{
    color: #1F1F1F;
    font-weight: 900;
    font-size: 1.8rem;
}

.contact-info-text{
    color: #1F1F1F;
    font-size: 1.2rem;
}

.contact_info_manifest--div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact_info--settings_div{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

/*Galaxy z fold 5*/
@media only screen and (max-width: 344px) and (max-height: 882px){
    
    .contact_main--title{
        font-size: 4.5rem;
    }
    .contact_section{
        width: 100%;
        flex-direction: column;
        padding-left: 0;
        padding: 1rem;
    }

    .contact_email--div{
        width: 100%;
    }

    .contact_email--input{
        width: 90%;
    }

    .contact_email--button_div{
        width: 90%;
    }

    .contact_info--div{
        width: 100%;
    }
}

/* Moto g4*/
@media only screen and (max-width: 360px) and (max-height: 740px) {
    
    .contact_main--title{
        font-size: 4.5rem;
    }
    .contact_section{
        width: 100%;
        flex-direction: column;
        padding-left: 0;
        padding: 1rem;
    }

    .contact_email--div{
        width: 100%;
    }

    .contact_email--input{
        width: 90%;
    }

    .contact_email--button_div{
        width: 90%;
    }

    .contact_info--div{
        width: 100%;
    }
}

/* Iphone SE/IPHONE 6,7,8*/
@media only screen and (min-width:361px) and (max-width: 375px) and (max-height: 667px){
    .contact_main--title{
        font-size: 4.5rem;
    }

    .contact_section{
        width: 100%;
        flex-direction: column;
        padding-left: 1rem;
    }

    .contact_email--div{
        width: 100%;
    }

    .contact_email--input{
        width: 90%;
    }

    .contact_email--button_div{
        width: 90%;
    }

    .contact_info--div{
        width: 100%;
    }
}

/*Sansung Galaxy A51/71, Pixel 7*/
@media only screen and (min-width:375px) and (max-width: 412px) and (max-height: 915px){
    .contact_main--title{
        font-size: 4.5rem;
    }

    .contact_section{
        width: 100%;
        flex-direction: column;
        padding-left: 1rem;
    }

    .contact_email--div{
        width: 100%;
    }

    .contact_email--input{
        width: 90%;
    }

    .contact_email--button_div{
        width: 90%;
    }

    .contact_info--div{
        width: 100%;
    }
}

/*Iphone XR*/
@media only screen and (min-width:412px) and (max-width: 414px) and (max-height: 896px){
    .contact_main--title{
        font-size: 4.5rem;
    }

    .contact_section{
        width: 100%;
        flex-direction: column;
        padding-left: 1rem;
    }

    .contact_email--div{
        width: 100%;
    }

    .contact_email--input{
        width: 90%;
    }

    .contact_email--button_div{
        width: 90%;
    }

    .contact_info--div{
        width: 100%;
    }
}

/*Iphone 14 PRO MAX*/
@media only screen and (min-width:414px) and (max-width: 432px) and (max-height: 932px){
    .contact_main--title{
        font-size: 4.5rem;
    }

    .contact_section{
        width: 100%;
        flex-direction: column;
        padding-left: 1rem;
    }

    .contact_email--div{
        width: 100%;
    }

    .contact_email--input{
        width: 90%;
    }

    .contact_email--button_div{
        width: 90%;
    }

    .contact_info--div{
        width: 100%;
    }
}

/*Surface Duo*/
@media only screen and (min-width:432px) and (max-width: 540px) and (max-height: 720px){
    .contact_main--title{
        font-size: 4.5rem;
    }

    .contact_section{
        width: 100%;
        flex-direction: column;
        padding-left: 1rem;
    }

    .contact_email--div{
        width: 100%;
    }

    .contact_email--input{
        width: 90%;
    }

    .contact_email--button_div{
        width: 90%;
    }

    .contact_info--div{
        width: 100%;
    }
}
