.removeSlideCard_card--div{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 33%;
    height: 20vh;
}

.removeSlideCard_img{
    width: 100%;
    height: 90%;
}

.removeSlideCard_title--strong{
    position: absolute;
    bottom: 0;
}

.removeSlideSelected{
    border: solid;
    border-color: blue;
}