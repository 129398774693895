.search_main{
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    width: 100%;
}

.search_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    height: 60vh;
    transition: all 0.5s ease;
}

.search_main--title{
    font-size: 6.25rem;
    font-weight: 900;
    color: #1F1F1F;
    transition: all 0.5s ease;
}

.search_main.searched .search_main--title {
    opacity: 0;
    font-size: 0;
}

.search_main.searched .search_form {
    height: 10vh;
    margin-bottom: 2rem;
}

.search_main--div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    border-bottom: 1px solid;
    border-color: #929292;
    transition: width 0.5s ease;
}

.search_main.searched .search_main--div {
    width: 30%; 
    margin-top: 20px; 
}

.search_main--input{
    border-style: none;
}

.search_ico{
    width: 1.2rem;
    height: 1.2rem;
}

.posts_container {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
}

.posts_container.isEmpty{
    justify-content: center;
}

.search_legend{
    font-weight: bold;
}

/*Galaxy z fold 5*/
@media only screen and (max-width: 344px) and (max-height: 882px){
    
    .search_main--title{
        font-size: 4.5rem;
    }

    .search_main--div{
        width: 80%;
    }
    .search_main.searched .search_main--div {
        width: 70%;  
    }
}

/*Moto g4*/
@media only screen and (min-width: 344px) and (max-width: 360px) and (max-height: 640px){
    
    .search_main--title{
        font-size: 4.5rem;
    }

    .search_main--div{
        width: 80%;
    }
    .search_main.searched .search_main--div {
        width: 70%;  
    }
}

/*Samsung galaxy s8+*/
@media only screen and (min-width: 344px) and (max-width: 360px) and (height: 740px){
    
    .search_main--title{
        font-size: 4.5rem;
    }

    .search_main--div{
        width: 80%;
    }
    .search_main.searched .search_main--div {
        width: 70%;  
    }
}

/*Iphone SE*/
@media only screen and (min-width: 360px) and (max-width: 375px) and (max-height: 667px){
    
    .search_main--title{
        font-size: 4.5rem;
    }

    .search_main--div{
        width: 80%;
    }
    .search_main.searched .search_main--div {
        width: 70%;  
    }
}

/*Pixel 7*/
@media only screen and (min-width: 375px) and (max-width: 412px) and (height: 915px){
    
    .search_main--title{
        font-size: 4.5rem;
    }

    .search_main--div{
        width: 80%;
    }
    .search_main.searched .search_main--div {
        width: 70%;  
    }
}

/*Iphone XR*/
@media only screen and (min-width: 375px) and (max-width: 414px) and (max-height: 896px){
    
    .search_main--title{
        font-size: 4.5rem;
    }

    .search_main--div{
        width: 80%;
    }
    .search_main.searched .search_main--div {
        width: 70%;  
    }
}

/*Iphone 14 pro max*/
@media only screen and (min-width: 414px) and (max-width: 430px) and (max-height: 932px){
    
    .search_main--title{
        font-size: 4.5rem;
    }

    .search_main--div{
        width: 80%;
    }
    .search_main.searched .search_main--div {
        width: 70%;  
    }
}

