.profile_main{
    display: flex;
    position: relative;
    height: 90vh;
}

.modalOpened{
    filter: blur(5px);
}

.overlay{
    display: none;
    height: 90vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.active{
    display: block;
    z-index: 1;
}

.profile_sidebar{
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 15%;
    gap: 12rem;
    border-right: solid 0.5px #C2C2C2;
}

.profile_sidebar_top{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem 0 0 0;
    gap: 3.5rem;
}

.profile_sidebar_logo{
    width: 120px;
    height: 150px;
}

.profile_sidebar--nav{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.profile_sidebar--li_div{
    display: flex;
    justify-content: flex-start;
    padding-left: 2rem;
    gap: 1rem;
}

.profile_sidebar--ul{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.profile_sidebar_li--ico{
    width: 1.5rem;
    height: 1.5rem;
}

.profile_sidebar--info_div{
    display: flex;
    gap: 1.5rem;
}

.profile_sidebar--info_email{
    text-align: center;
}

.profile_sidebar--email{
    font-size: 0.9rem;
    color: #0066CC;
}

.profile_sidebar--email_join{
    font-size: 0.9rem;
}

.profile_sidebar--logout_link{
    font-size: 1.25rem;
    color: #0066CC;
}

.profile_main_section{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile_mainContent{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile_profileImg{
    width: 10rem;
    height: 10rem;
    border-radius: 3rem;
    object-fit: cover;
}
