.budgetInfo_main{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
}

.budgetInfo_content--section{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    height: 55vh;
    padding: 10px;
    background-color: #c0c0c0;
    border-radius: 10px;
    box-sizing: border-box;
    overflow-y: auto;
}

.budgeInfo_content--itemDiv{
    box-sizing: border-box;
    width: calc(50% - 20px);
    padding: 10px;
}
