.slide_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
}

.slide_section--img {
    width: 100%;
    height: 100vh;
}

.slidecard_link{
    height: 100vh;
}

.slide_card {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    height: 90vh;
}

.slide_section--ico {
    position: absolute;
    padding: 1rem 0 0 0;
    z-index: 1;
}

.slick-dots {
    bottom: 3rem;
}

.slick-dots li button::before {
    color: white;
}

.slide_arrowdown {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    position: absolute;
    bottom: 1.5rem;
    animation: arrowdown-reverse 1s ease forwards;

}

.slide_arrowdown:hover {
    animation: arrowdown-move 1s ease forwards;
}

@keyframes arrowdown-move {
    100% {
        bottom: 1rem;
    }
}

@keyframes arrowdown-reverse {
    0% {
        bottom: 1rem;
    }

    100% {
        bottom: 1.5rem;
    }
}