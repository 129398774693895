.home_main{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
}

#home_card-section{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 20vh;
}

.arrowDown_div{
    display: flex;
    justify-content: center;
    width: 100%;
}

.arrowDown_link {
    position: relative;
    animation: arrowdown_reverse 1s ease forwards;
}

.arrowDown_link:hover{
    animation: arrowdown_move 1s ease forwards;
}

@keyframes arrowdown_move {
    0%{
        top: 0;
    }
    100%{
        top: 1rem;
    }
}

@keyframes arrowdown_reverse {
    0% {
        top: 1rem;
    }
    100%{
        top: 0;
    }
}
