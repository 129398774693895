.addPost_form{
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.addPost_form--inputDiv{
    display: flex;
    flex-direction: column;
}

.addPost_form--input{
    width: 100%;
}
