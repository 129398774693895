.slidecard_link{
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.slidecard_strong{
    position: absolute;
    bottom: 10vh;
    color: #ffffff;
    opacity: 0;
    font-size: 1.5rem;
}

.show {
    animation: fadeIn 1s forwards;
}

.slidecard_img{
    width: 100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
