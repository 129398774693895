.budget_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0rem 0 1rem 0;
}

.budget_main--section{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.budget_main--top_section{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
    gap: 1rem;
    z-index: 1;
}

.video-background::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%; /* Altura do gradiente */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

.video-background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.video-background--iframe{
    width: 100%;
    height: 100vh;
    object-fit:fill;
    object-position: center;
    border: none;
}

.budget_logo--img{
    width: 7rem;
    height: 7rem;
    margin-top: 2rem;
    z-index: 1;
}

.budget_title--div{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}


.budget_title--text{
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 2.81rem;
    line-height: 3.2rem;
    color: #000000;
}

.budget_info--div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 4rem 0 4rem 0;
}

.budget_form--subtitle_about{
    display: flex;
    align-items: flex-end;
    padding-bottom: 1.1rem;
    width: 100%;
    height: 10vh;
    border-bottom: 1px solid #929292a8;
}

.budget_info--text{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.46rem;
    text-align: justify;
}

.budget_info--strong{
    font-family: "Roboto", sans-serif;
    font-size: 1.25rem;
    line-height: 1.46rem;
    text-align: justify;
}

.budget_form{
    display: flex;
    flex-direction: column;
}

.budget_form--div{
    display: flex;
    align-items: center;
    height: 6vh;
    width: 100%;
    border-bottom: 1px solid #929292a8;
}

.input{
    align-items: center;
    gap: 1rem;
}

.budget_form--select{
    text-align:right;
    border: none;
    color: #929292;
    background-color: #ffffff;
    font-size: 1.25rem;
    background-repeat: no-repeat;
    background-position: right 10px center; /* Ajustar a posição da seta */
}

.toogle_input--div{
    align-items: center;
    justify-content: space-between;
}

.budget_form--title_text{
    font-family: "Roboto", sans-serif;
    font-size: 1.8rem;
    font-weight: 900;
    line-height: 2rem;
}

.budget_form--input{
    border: none;
    width: 100%;
    padding: 0;
}

.budget_form--subtitle_text{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.46rem;
    width: 100%;
}

.budget_form--local_text{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.46rem;
    width: 100%;
}

.budget_form--input_div{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.budget_input--text{
    font-size: 1.25rem;
    line-height: 1.46rem;
    font-weight: 400;
    color: #929292;
}

.budget_form--button_div{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem;
}

.budget_form--button{
    width: 6.5rem;
    height: 3.2rem;
    border-radius: 10px 10px 10px 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 1.4rem;
    font-size: 1.25rem;
    background-color: #0066CC;
}

@media only screen and (max-width: 359px){

    .budget_title--text{
        font-size: 1.4rem;
        line-height: normal;
    }
    .budget_info--div{
        padding: 2.5rem 0.5rem 2.5rem 0.5rem;
    }
    .budget_info--text{
        font-size: 0.9rem;
        line-height: normal;
    }
    .budget_form--subtitle_about{
        padding-bottom: 0.2rem;
    }
    .budget_form--div{
        height: 6vh;
    }
    .budget_info--strong{
        font-size: 0.9rem;
        line-height: normal;
    }
    .budget_main--section{
        width: 100%;
    }
    .budget_form{
        padding: 0 1rem 0 1rem;
    }
    .budget_form--title_text{
        font-size: 1.3rem;
    }
    .budget_form--subtitle_text{
        font-size: 0.7rem;
        line-height: normal;
    }
    .budget_input--text{
        font-size: 0.7rem;
    }
    .budget_form--local_text{
        font-size: 0.61rem;
    }
    .budget_input--text{
        font-size: 0.8rem;
    }
    .budget_form--select{
        width: 80%;
        font-size: 0.6rem;
    }
    .budget_form--button_div{
        margin-top: 1rem;
    }
    .budget_form--button{
        width: 3.6rem;
        height: 3rem;
        font-size: 1rem;
    }
}

@media only screen and (min-width: 360px) and (max-width: 414px){

    
    .budget_title--text{
        font-size: 1.5rem;
        line-height: normal;
    }
    .budget_info--div{
        padding: 2.5rem 0.5rem 2.5rem 0.5rem;
    }
    .budget_info--text{
        font-size: 1rem;
    }
    .budget_form--subtitle_about{
        height: 12vh;
        padding-bottom: 0.6rem;
    }
    .budget_info--strong{
        font-size: 1.1rem;
    }
    .budget_main--section{
        width: 100%;
    }
    .budget_form{
        padding: 0 1rem 0 1rem;
    }
    .budget_form--title_text{
        font-size: 1.5rem;
    }
    .budget_form--subtitle_text{
        font-size: 0.8rem;
        line-height: normal;
    }
    .budget_form--local_text{
        font-size: 0.7rem;
    }
    .budget_input--text{
        font-size: 0.8rem;
    }
    .budget_form--select{
        width: 80%;
        font-size: 0.6rem;
    }
    .budget_form--button_div{
        margin-top: 1rem;
    }
    .budget_form--button{
        width: 4.5rem;
        height: 3rem;
    }
}

@media only screen and (min-width: 415px) and (max-width: 566px){
    
   .budget_main--section{
    width: 100%;
   } 
   .budget_title--text{
    font-size: 1.5rem;
    line-height: normal;
   }
   .budget_form--subtitle_about{
    margin-top: 2.5rem;
    }
   .budget_info--div{
    padding: 2rem 0 2rem 0;
   }
   .budget_input--text{
    font-size: 1rem;
   }
   .budget_info--text{
    font-size: 1rem;
   }
   .budget_info--strong{
    font-size: 1.2rem;
   }
   .budget_form--title_text{
    font-size: 1.5rem;
   }
   .budget_form--subtitle_text{
    font-size: 1rem;
    width: 80%;
   }
   .budget_form--local_text{
    font-size: 0.8rem;
}
   .budget_form--select{
    width: 70%;
    font-size: 1rem;
   }
   .budget_form--button_div{
    margin-top: 1rem;
    }
    .budget_form--button{
    width: 4.5rem;
    height: 2.5rem;
    font-size: 1rem;
    }
}

@media only screen and (min-width: 567px) and (max-width: 768px){

    
    .budget_title--text{
        font-size: 2.5rem;
    }
    .budget_info--div{
        padding: 2.5rem 0.5rem 2.5rem 0.5rem;
    }
    .budget_form--subtitle_about{
        height: 100px;
        padding-bottom: 0.8rem;
    }
    .budget_form--div{
        height: 50px;
    }
    .budget_info--text{
        font-size: 1.4rem;
    }
    .budget_info--strong{
        font-size: 1.6rem;
    }
    .budget_main--section{
        width: 100%;
    }
    .budget_form{
        padding: 0 1rem 0 1rem;
    }
    .budget_form--subtitle_text{
        font-size: 1.2rem;
    }
    .budget_input--text{
        font-size: 1.2rem;
    }
    .budget_form--select{
        width: 80%;
        font-size: 1rem;
    }
    .budget_form--button_div{
        margin-top: 2rem;
    }
    .budget_form--button{
        width: 6rem;
        height: 3.2rem;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1300px){
    
    .budget_main--section{
        width: 100%;
    }
    .budget_title--text{
        font-size: 2.5rem;
    }
    .budget_form--title_text{
        font-size: 1.8rem;
    }
    .budget_form--subtitle_text{
        width: 70%;
        font-size: 1.3rem;
    }
    .budget_form--subtitle_about{
        height: 20vh;
        padding-bottom: 0.5rem;
    }
    .budget_form--div{
        height: 10vh;
    }
    .budget_form--button{
        width: 6rem;
        height: 4rem;
    }
}

@media only screen and (min-width: 820px) and (max-width: 1180px){
    
    .budget_form--subtitle_about {
        height: 150px;
    }
    .budget_form--div{
        height: 5vh;
    }
}

@media only screen and (width: 1024px) and (max-width: 1366px){

    .budget_info--div{
        padding: 2rem 0 2rem 0;
    }
    .budget_form--subtitle_about{
        height: 10vh;
    }
    .budget_form--div {
        height: 6vh;
    }
    .budget_form--subtitle_text {
        font-size: 1.5rem;
    }
    .budget_form--select{
        font-size: 1.5rem;
    }

}

@media only screen and (width: 912px) and (max-width: 1368px){

    .budget_form--subtitle_about{
        height: 10vh;
    }
    .budget_form--div{
        height: 5vh;
    }
    .budget_form--subtitle_text{
        font-size: 1.5rem;
    }
    .budget_input--text{
        font-size: 1.5rem;
    }
    .budget_form--title_text{
        font-size: 2.2rem;
    }
}

@media only screen and (width: 430px) and (height: 932px){
    .budget_form{
        padding: 2rem 1rem 0 1rem;
    }
}

@media only screen and (width: 812px) and (height: 375px){
    .budget_form--div{
        height: 15vh;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1300px) and (max-height: 430px){

    .budget_form--button_div{
        margin-top: 1rem;
    }
}
