.message_main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f4;
    margin-top: 10vh;
    height: 90vh;
  }
  
  .message_card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .message_card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .message_name {
    font-size: 1.5em;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .message_email {
    font-size: 1em;
    color: #555555;
    margin-bottom: 10px;
  }
  
  .message_date {
    font-size: 0.9em;
    color: #777777;
    margin-bottom: 15px;
  }
  
  .message_content {
    font-size: 1em;
    color: #333333;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
  }