.contactCard_link {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 250px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .contactCard_link h3 {
    margin: 0 0 8px;
    color: #333;
  }
  
  .contactCard_link p {
    margin: 4px 0;
    color: #666;
  }
  
  .contactCard_link:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }