.post_main{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
}

#postInfo_section{
    display: flex;
    flex-direction: column;
    padding: 6rem 2.5rem 2.5rem 2.5rem; 
    width: 100%;
}

.postInfo_section--div{
    display: flex;
    width: 100%;
}

.postInfo_section--about{
    display: flex;
    gap: 10%;
    padding: 0 2.5rem 0 2.5rem;
    margin-bottom: 4rem;
}

.postInfo_div--title{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.postInfo_div--strong{
    font-size: 2.5rem;
}

.postInfo_div--span{
    font-size: 1.2rem;
}

.postInfo_div--category{
    display: flex;
    flex-wrap: wrap;
    width: 60%;
}

.postInfo_category--info{
    display: flex;
    width: 50%;
}

.postInfo_category--info{
    margin-bottom: 0.5rem;
    gap: 0.2rem;
}

.post_mainImage--section{
    display: flex;
    justify-content: center;
    position: relative;
}

.post_mainImage--img{
    width: 100%;
    height: 90vh;
    object-fit: fill;
}

.post_mainImage--title{
    position: absolute;
    color: #ffffff;
    bottom: 5vh;
}

.postInfo_section--media{
    display: flex;
    width: 100%;
    padding-left: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.postInfo--img{
    width: 49%;
    height: 500px;
    padding-bottom: 1rem;
    object-fit: fill;
}

.postInfo--video{
    width: 49%;
    height: 500px;
    padding-bottom: 1rem;
}

/*Configuração mobile 1*/
@media only screen and (min-width: 300px) and (max-width: 767px){
    
    .post_mainImage--img{
        height: 400px;
        object-fit: cover;
    }

    #postInfo_section{
        padding: 6rem 0 2.5rem 1rem;
    }

    .postInfo_section--div{
        flex-direction: column;
    }

    .postInfo_div--title{
        width: 100%;
        margin-bottom: 1rem;
    }

    .postInfo_div--category{
        width: 100%;
    }

    .postInfo_category--info{
        width: 100%;
    }

    .postInfo_section--about{
        flex-direction: column;
        align-items: center;
    }

    .postInfo_section--description p{
        text-align: justify;
    }

    .postInfo--img{
        width: 100%;
        object-fit: fill;
        height: 300px;
    }

    .postInfo--video{
        width: 100%;
        height: 300px;
        padding-bottom: 1rem;
    }
}



/*Moto G4 Virado*/
@media only screen and (width: 640px) and (height: 360px){
    .post_mainImage--img{
        height: 80vh;
        object-fit: cover;
    }

    .postInfo_div--title{
        width: 100%;
    }

    .postInfo_div--category{
        width: 100%;
    }

    .postInfo_category--info{
        width: 100%;
    }

    .postInfo--img{
        width: 100%;
        object-fit: fill;
        height: 300px;
    }

    .postInfo--video{
        width: 100%;
        height: 300px;
        padding-bottom: 1rem;
    }
}

/*Configuração mobile 2*/
@media only screen and (min-width: 768px) and (max-width: 820px){

    .postInfo--img{
        width: 48%;
        object-fit: fill;
        height: 300px;
    }

    .postInfo--video{
        width: 48%;
        height: 300px;
        padding-bottom: 1rem;
    }
}

/*Iphone SE virado*/
@media only screen and (width: 667px) and (height: 375px){
    .post_mainImage--img{
        height: 80vh;
        object-fit: cover;
    }

    .postInfo_div--title{
        width: 100%;
    }

    .postInfo_div--category{
        width: 100%;
    }

    .postInfo_category--info{
        width: 100%;
    }

    .postInfo--img{
        width: 100%;
        object-fit: fill;
        height: 300px;
    }

    .postInfo--video{
        width: 100%;
        height: 300px;
        padding-bottom: 1rem;
    }
}



/*Iphone XR virado*/
@media only screen and (width: 896px) and (height: 414px){

    .postInfo--img{
        object-fit: fill;
        height: 400px;
    }

    .postInfo--video{
        height: 400px;
        padding-bottom: 1rem;
    }
}

/*Iphone 12 pro virado*/
@media only screen and (width: 844px) and (height: 390px){

    .postInfo--img{
        object-fit: fill;
        height: 400px;
    }

    .postInfo--video{
        height: 400px;
        padding-bottom: 1rem;
    }
}


/*Iphone 14 pro virado*/
@media only screen and (width: 932px) and (height: 430px){

    .postInfo--img{
        object-fit: fill;
        height: 400px;
    }

    .postInfo--video{
        height: 400px;
        padding-bottom: 1rem;
    }
}


/*Pixel 7 virado*/
@media only screen and (width: 915px) and (height: 412px){

    .postInfo--img{
        object-fit: fill;
        height: 400px;
    }

    .postInfo--video{
        height: 400px;
        padding-bottom: 1rem;
    }
}

/*Samsung galaxy s8+ virado*/
@media only screen and (width: 740px) and (height: 360px){

    .postInfo_section--div{
        flex-direction: row;
    }

    .postInfo_category--info{
        width: 50%;
    }

    .postInfo--img{
        object-fit: fill;
        width: 48%;
        height: 400px;
    }

    .postInfo--video{
        height: 400px;
        width: 48%;
        padding-bottom: 1rem;
    }
}

/*Iphone 6/7/8 virado*/
@media only screen and (width: 667px) and (height: 375px){

    .postInfo_section--div{
        flex-direction: row;
    }

    .postInfo_div--title{
        width: 75%;
    }

    .postInfo_category--info{
        width: 50%;
    }

    .postInfo--img{
        object-fit: fill;
        width: 48%;
        height: 400px;
    }

    .postInfo--video{
        height: 400px;
        width: 48%;
        padding-bottom: 1rem;
    }
}
