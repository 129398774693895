.budgetItem_container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50vw;
    height: 75px;
    background-color: #c0c0c0;
    margin: 0  0 20px 0;
    border-radius: 10px;
    border: 2px solid #b0b0b0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.budgetItem_container:hover {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.12); /* Aumenta a sombra ao passar o mouse */
    transform: translateY(-2px); /* Eleva ligeiramente ao passar o mouse */
}

.budgetItem_container:active {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.12); /* Reduz a sombra ao clicar */
    transform: translateY(1px); /* Move ligeiramente para baixo ao clicar */
}

.budgetItem_location--div{
    display: flex;
    flex-direction: column;
}