@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  background-color: #FFFFFF;
  color: #333;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar{
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button,
input,
textarea {
  font: inherit;
}

button {
  cursor: pointer;
  border: none;
  background: none;
}

img {
  max-width: 100%;
  display: block;
}

table {
  border-collapse: collapse;
}

caption,
th,
td {
  text-align: left;
  padding: 8px;
}

fieldset {
  border: none;
}

legend {
  padding: 0;
}

textarea {
  resize: none;
}

/* Adicionando estilos básicos para inputs */
input,
textarea {
  outline: none;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
}

button {
  color: white;
}