.removePostCard_card--div{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 33%;
    height: 20vh;
}

.removePostCard_img{
    width: 100%;
    height: 90%;
}

.removePostCard_title--strong{
    position: absolute;
    bottom: 0;
}

.removePostSelected{
    border: solid;
    border-color: blue;
}