.homeHeader_main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10vh;
    padding: 1rem 2rem 0 2rem;
    background-color: rgba(255, 255, 255, 0.3);
    transition: transform 0.3s ease, top 0.3s ease;
    z-index: 1000;
}

.homeHeader_main.sticky {
    position: fixed;
    top: 0;
    transform: translateY(0);
    background-color: rgba(255, 255, 255, 0.3);
}

.homeHeader_img--logo{
    width: 2.5rem;
    height: 2.5rem;
}

.homeHeader_nav--ul{
    display: flex;
    gap: 2rem;
}

.homeHeader_nav--li_text{
    color: #1F1F1F;
    font-size: 1.2rem;
}

.selected{
    font-weight: bold;
    font-weight: 700;
}

.homeHeader_img--search{
    width: 1.2rem;
    height: 1.2rem;
}

.hamburguer {
    display: none;
    cursor: pointer;
    flex-direction: column;
    gap: 4px;
  }
  
.hamburguer .bar {
    width: 25px;
    height: 3px;
    background-color: #000;
}

/*Galaxy Z fold 5*/
@media only screen and (max-width: 344px) and (max-height: 882px) {
    .homeHeader_nav{
        display: none;
    }


    .homeHeader_search--link{
        display: none;
    }

    .hamburguer{
        display: flex;
    }
}

/*Galaxy Z fold 5 Virado*/
@media only screen and (width: 882px) and (height: 344px){
    .homeHeader_main{
        height: 20vh;
    }
}

/* Moto g4*/
@media only screen and (max-width: 360px) and (max-height: 740px) {
    .homeHeader_nav{
        display: none;
    }


    .homeHeader_search--link{
        display: none;
    }

    .hamburguer{
        display: flex;
    }
}

/*Moto G4 Virado*/
@media only screen and (width: 640px) and (height: 360px){
    .homeHeader_main{
        height: 20vh;
    }
}

/* Iphone SE/IPHONE 6,7,8*/
@media only screen and (min-width:361px) and (max-width: 375px) and (max-height: 667px){
    
    .homeHeader_nav{
        display: none;
    }


    .homeHeader_search--link{
        display: none;
    }

    .hamburguer{
        display: flex;
    }
}
/*Iphone SE virado */
@media only screen and (width: 667px) and (height: 375px){
    .homeHeader_main{
        height: 20vh;
    }
}

/*Sansung Galaxy A51/71, Pixel 7*/
@media only screen and (min-width:375px) and (max-width: 412px) and (max-height: 915px){

    .homeHeader_nav{
        display: none;
    }


    .homeHeader_search--link{
        display: none;
    }

    .hamburguer{
        display: flex;
    }
}

/*Iphone 12 pro virado*/
@media only screen and (width: 844px) and (height: 390px){
    .homeHeader_main{
        height: 15vh;
    }
}

/*Iphone XR*/
@media only screen and (min-width:412px) and (max-width: 414px) and (max-height: 896px){

    .homeHeader_nav{
        display: none;
    }


    .homeHeader_search--link{
        display: none;
    }

    .hamburguer{
        display: flex;
    }
}

/*Iphone XR virado*/
@media only screen and (width: 896px) and (height: 414px){
    .homeHeader_main{
        height: 20vh;
    }
}

/*Iphone 14 PRO MAX*/
@media only screen and (min-width:414px) and (max-width: 432px) and (max-height: 932px){

    .homeHeader_nav{
        display: none;
    }


    .homeHeader_search--link{
        display: none;
    }

    .hamburguer{
        display: flex;
    }
}

/*Iphone 14 pro max virado*/
@media only screen and (width: 932px) and (height: 430px){
    .homeHeader_main{
        height: 15vh;
    }
}

/*Surface Duo*/
@media only screen and (min-width:432px) and (max-width: 540px) and (max-height: 720px){

    .homeHeader_nav{
        display: none;
    }


    .homeHeader_search--link{
        display: none;
    }

    .hamburguer{
        display: flex;
    }
}

/*Pixel 7 virado*/
@media only screen and (width: 915px) and (height: 412px){
    .homeHeader_main{
        height: 15vh;
    }
}
