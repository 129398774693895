.footer_main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    bottom: 0;
    height: 10vh;
    width: 100%;
    padding: 0 2rem;
}

.footer_main--socials{
    display: flex;
    gap: 0.5rem;
}

.footer_main--img{
    width: 2rem;
    height: 2rem;
}

@media only screen and (max-width: 359px){
    .footer_main{
        padding: 0 1rem;
        gap: 1rem;
    }
    .footer_copyright--text{
        font-size: 0.6rem;
    }
    
}

@media only screen and (min-width: 360px) and (max-width: 414px){
    .footer_main{
        padding: 0 1rem;
        gap: 1rem;
    }
    .footer_copyright--text{
        font-size: 0.8rem;
    }
    
}

@media only screen and (min-width: 415px) and (max-width: 566px){
    .footer_main{
        gap: 1rem;
    }
    .footer_copyright--text{
        font-size: 0.8rem;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1300px){
    .footer_copyright--text{
        font-size: 1.5rem;
    }
}
