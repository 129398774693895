.editSlideOrderCard_card--div{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 33%;
    height: 20vh;
}

.editSlideOrderCard_img{
    width: 100%;
    height: 90%;
}