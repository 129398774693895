.card_link{
    display: flex;
    position: relative;
    justify-content: center;
    width: 50%;
}

.card_strong{
    position: absolute;
    bottom: 5vh;
    color: #ffffff;
    opacity: 0;
    font-size: 1.5rem;
}

.card_link.show {
    animation: fadeInUp 3s ease-in-out forwards;
}

.card_img{
    padding: 0.5rem;
    width: 100%;
    height: 54vh;
    object-fit: cover;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-up {
    animation: fadeInUp 0.5s ease-in-out forwards;
  }

  /*Galaxy Z fold 5*/
  @media only screen and (max-width: 344px) and (max-height: 882px) {
    .card_link{
        width: 100%;
    }

    .card_strong{
        opacity: 1;
    }
}

  /* Moto g4*/
@media only screen and (max-width: 360px) and (max-height: 740px) {
    .card_link{
        width: 100%;
    }

    .card_strong{
        opacity: 1;
    }
}

/* Moto g4 virado*/
@media only screen and (width: 640px) and (height: 360px) {
    .card_link{
        height: 300px;
    }
    .card_img{
        height: 100%;
    }
}

/* Iphone SE/IPHONE 6,7,8*/
@media only screen and (min-width:361px) and (max-width: 375px) and (max-height: 667px){
    .card_link{
        width: 100%;
    }

    .card_strong{
        opacity: 1;
    }
}

/* Iphone SE/IPHONE 6,7,8 virado*/
@media only screen and (width: 667px) and (height: 375px){
    .card_link{
        height: 300px;
    }
    .card_img{
        height: 100%;
    }
}

/*Sansung Galaxy A51/71, Pixel 7*/
@media only screen and (min-width:375px) and (max-width: 412px) and (max-height: 915px){
    .card_link{
        width: 100%;
    }

    .card_strong{
        opacity: 1;
    }
}

/*Samsung Galaxy S8+*/
@media only screen and (width: 740px) and (height: 360px){
    .card_link{
        height: 300px;
    }
    .card_img{
        height: 100%;
    }
}

/*Iphone XR*/
@media only screen and (min-width:412px) and (max-width: 414px) and (max-height: 896px){
    .card_link{
        width: 100%;
    }

    .card_strong{
        opacity: 1;
    }
}

/*Iphone 14 PRO MAX*/
@media only screen and (min-width:414px) and (max-width: 432px) and (max-height: 932px){
    
    .card_link{
        width: 100%;
    }

    .card_strong{
        opacity: 1;
    }
}

/*Surface Duo*/
@media only screen and (min-width:432px) and (max-width: 540px) and (max-height: 720px){
    
    .card_link{
        width: 100%;
    }

    .card_strong{
        opacity: 1;
    }
}

/*Ipad Mini*/
@media only screen and (min-width: 541px) and (max-width: 768px) and (max-height: 1024px){
    
    .card_link{
        width: 100%;
    }

    .card_strong{
        opacity: 1;
    } 
}

/*Ipad air*/
@media only screen and (min-width: 769px) and (max-width: 820px) and (max-height: 1180px){
    
    .card_link{
        width: 100%;
    }

    .card_strong{
        opacity: 1;
    } 
}

/*Ipad pro*/
@media only screen and (min-width: 821px) and (max-width: 1024px) and (max-height: 1366px){

    .card_strong{
        opacity: 1;
    } 
}

/*Surface Pro 7*/
@media only screen and (min-width: 821px) and (max-width: 912px) and (max-height: 1368px){

    .card_strong{
        opacity: 1;
    } 
}

/*Nest hub max*/
@media only screen and (width: 1280px) and (height: 800px){

    .card_strong{
        opacity: 1;
    } 
}
