.headerModal_nav{
    display: none;
    background-color: rgba(255, 255, 255, 0.2); /* Fundo branco com 90% de opacidade */
    color: #1F1F1F; /* Cor escura para o texto */
}

@media only screen and (max-width: 360px) and (max-height: 740px) {
    .open{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerModal_nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        backdrop-filter: blur(8px); 
    }
    .headerModal_nav ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}

/* Iphone SE/IPHONE 6,7,8*/
@media only screen and (min-width:361px) and (max-width: 375px) and (max-height: 667px){

    .open{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerModal_nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        backdrop-filter: blur(8px); 
    }
    .headerModal_nav ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}

/*Galaxy Z fold 5*/
@media only screen and (max-width: 344px) and (max-height: 882px){

    .open{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerModal_nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        backdrop-filter: blur(8px); 
    }
    .headerModal_nav ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}

/*Sansung Galaxy A51/71, Pixel 7*/
@media only screen and (min-width:375px) and (max-width: 412px) and (max-height: 915px){

    .open{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerModal_nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        backdrop-filter: blur(8px); 
    }
    .headerModal_nav ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}

/*Iphone XR*/
@media only screen and (min-width:412px) and (max-width: 414px) and (max-height: 896px){

    .open{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerModal_nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        backdrop-filter: blur(8px); 
    }
    .headerModal_nav ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}

/*Iphone 14 PRO MAX*/
@media only screen and (min-width:414px) and (max-width: 432px) and (max-height: 932px){

    .open{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerModal_nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        backdrop-filter: blur(8px); 
    }
    .headerModal_nav ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}

/*Surface Duo*/
@media only screen and (min-width:432px) and (max-width: 540px) and (max-height: 720px){

    .open{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerModal_nav{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        backdrop-filter: blur(8px); 
    }
    .headerModal_nav ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}