.slidePostCard_card--div{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 33%;
    height: 20vh;
}

.slidePostCard_img{
    width: 100%;
    height: 90%;
}

.slidePostCard_title--strong{
    position: absolute;
    bottom: 0;
}

.slideSelected{
    border: solid;
    border-color: blue;
}
