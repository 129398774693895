.slideModal_section{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 90vh;
    background-color: #C0C0C0;
    display: none;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    z-index: 10;
}

.opened{
    display: flex;
    flex-direction: column;
    animation: riseUp 0.5s ease-in;
}

.slideModal_section--header{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 10vh;
    padding: 1rem 1rem 0 1rem;
}

.slideModal_section--container{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.slideModal_section--navbar{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.slideModal_content--section{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: 1rem;
}


@keyframes riseUp {
    0%{
        width: 0%;
        height: 0vh;
    } 100%{
        width: 50%;
        height: 90vh;
    }
}
