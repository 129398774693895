.addSlide_form{
    width: 100%;
    padding: 1rem;
}

.addSlide_div--button{
    display: flex;
    gap: 1rem;
}

.addSlide_form--buttonDiv{
    display: flex;
    justify-content: flex-end;
    width: 90%;
}

.addSlide_form--postDiv{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 50vh;
    margin-top: 1rem;
}

.addSlide_button--page{
    width: 5rem;
    background-color: #000000;
    border-radius: 0.6rem;
}
