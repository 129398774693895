.switch{
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 2rem;
}

.switch input{
    opacity: 0;
    width: 0;
    height: 0;
}

.slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 1rem;
    width: 100%;
}

.slider::before{
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    top: 50%;
    transform: translateY(-50%);
    left: 2px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 1rem;
}

.switch input:checked + .slider {
    background-color: #0066CC;
    transition: 0.4s;
}

.switch input:checked + .slider::before {
    transform: translate(18px, -50%);
}

@media only screen and (max-width: 359px){
    .switch{
        width: 2.6rem;
        height: 2rem;
    }
    .slider::before{
        left: 3px;
        height: 23px;
        width: 23px;
    }
    .switch input:checked + .slider::before {
        transform: translate(13px, -50%);
    }
}

@media only screen and (min-width: 360px) and (max-width: 414px){
    .switch{
        width: 2.8rem;
        height: 2rem;
    }
    .slider::before{
        left: 3px;
        height: 26px;
        width: 26px;
    }
    .switch input:checked + .slider::before {
        transform: translate(14px, -50%);
    }
}

@media only screen and (min-width: 415px) and (max-width: 566px){
    .switch{
        width: 2.9rem;
        height: 2.1rem;
    }
    .slider::before{
        left: 3px;
        height: 25px;
        width: 25px;
    }
    .switch input:checked + .slider::before {
        transform: translate(15px, -50%);
    }
}

@media only screen and (min-width: 567px) and (max-width: 768px){

    
    .budget_title--text{
        font-size: 2.5rem;
    }
    .budget_info--div{
        padding: 2.5rem 0.5rem 2.5rem 0.5rem;
    }
    .budget_info--text{
        font-size: 1.4rem;
    }
    .budget_info--strong{
        font-size: 1.6rem;
    }
    .budget_main--section{
        width: 100%;
    }
    .budget_form{
        padding: 0 1rem 0 1rem;
    }
    .budget_form--subtitle_text{
        width: 70%;
        font-size: 1.2rem;
    }
    .budget_input--text{
        font-size: 0.8rem;
    }
    .budget_form--select{
        width: 80%;
        font-size: 1rem;
    }
    .budget_form--button_div{
        margin-top: 1rem;
    }
    .budget_form--button{
        width: 6rem;
        height: 3.2rem;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1300px){
    .switch{
        width: 2.9rem;
        height: 2rem;
    }
    .slider::before{
        height: 26px;
        width: 26px;
    }
    .switch input:checked + .slider::before {
        transform: translate(1rem, -50%);
    }
}
