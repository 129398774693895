.login_main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 80vh;
}

.login_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.login_main--form_text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 2rem;
}

.login_form--title{
    font-size: 6.25rem;
    font-weight: bold;
    line-height: 0.6;
}

.login_form--subtitle{
    font-size: 1.8rem;
}

.login_form--input_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.login_form--email_input-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 3.6rem;
    border: #929292 0.0625rem solid;
    border-radius: 0.625rem;
}

.selected{
    border-color: rgba(5, 5, 5, 0.76)
}

.login_form--email_input{
    width: 80%;
    border: none;
    border-radius: 0.625rem;
}

.login_form--email_button{
    width: 2.8rem;
    height: 2rem;
    padding-right: 0.8rem;
}

.login_form--email_loginNext-img{
    width: 100%;
    height: 100%;
}

.login_form--input_checkbox--div{
    display: flex;
    gap: 0.5rem;
    color: #929292;
}

.login_main--password-forget{
    color: #0066CC;
}

.login_main--password-forget{
    margin-top: 1rem;
}

/* Moto g4*/
@media only screen and (max-width: 360px) and (max-height: 740px) {
    
    .login_form{
        width: 75%;
    }
    
    .login_form--title{
        font-size: 4.5rem;
    }

    .login_form--email_input-div{
        width: 100%;
    }
}

/* Iphone SE/IPHONE 6,7,8*/
@media only screen and (min-width:361px) and (max-width: 375px) and (max-height: 667px){


    .login_form{
        width: 75%;
    }
    
    .login_form--title{
        font-size: 4.5rem;
    }

    .login_form--email_input-div{
        width: 100%;
    }
}

/*Sansung Galaxy A51/71, Pixel 7*/
@media only screen and (min-width:375px) and (max-width: 412px) and (max-height: 915px){

    .login_form{
        width: 75%;
    }
    
    .login_form--title{
        font-size: 5.5rem;
    }

    .login_form--email_input-div{
        width: 100%;
    }
}

/*Iphone XR*/
@media only screen and (min-width:412px) and (max-width: 414px) and (max-height: 896px){
    
    .login_form{
        width: 75%;
    }
    
    .login_form--title{
        font-size: 6.5rem;
    }

    .login_form--email_input-div{
        width: 100%;
    }
}

/*Iphone 14 PRO MAX*/
@media only screen and (min-width:414px) and (max-width: 432px) and (max-height: 932px){

    .login_form{
        width: 75%;
    }
    
    .login_form--title{
        font-size: 6.5rem;
    }

    .login_form--email_input-div{
        width: 100%;
    }
}

/*Surface Duo*/
@media only screen and (min-width:432px) and (max-width: 540px) and (max-height: 720px){
    
    .login_form{
        width: 75%;
    }
    
    .login_form--title{
        font-size: 6.5rem;
    }

    .login_form--email_input-div{
        width: 100%;
    }
}
